









































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import BaseTabOption from '@improve/common-utils/src/types/TabOption';
import BaseTabs from './BaseTabs.vue';

@Component({
  name: 'BasePageHeader',
  components: {
    BaseTabs
  }
})
export default class BasePageHeader extends Vue {
  @Prop({ default: () => [] }) readonly tabs!: Array<BaseTabOption>;

  @Prop({ default: null }) readonly currentTab!: string;

  @Prop({ default: 940 }) readonly maxWidthPixel!: number;

  @Prop({ default: '#f3f3f3' }) readonly backgroundColor!: string;
}
